import request from "@/utils/request";

// 标题显示
export function CaseListTitle() {
  return request.get("/goods/partnerCase/listType");
}

// 列表页面查询
export function AllPartnerCase(query) {
  return request.get("/goods/partnerCase/allListPartnerCase", query);
}

// 查询详情页面
export function CaseById(id) {
  return request.get(`/goods/partnerCase/getById/${id}`);
}
