<template>
  <AppLayout>
    <div class="content-warp">
      <div class="warp-title">
        <div class="warp1-title1">
          <p class="warp-title-text" @click="$router.back(-1)">
            <span> <img src="../../assets/images/fh.png" alt="" /></span
            >合作案例
          </p>
          <p class="warp-title-content">{{ title }}</p>
          <el-divider class="divider-border" />
          <div class="warp-img" v-html="details"></div>
          <div class="we-content" v-if="caseByIdList">
            <p class="we-content-t">我们为其提供的产品</p>
            <div class="we-all">
              <div
                v-for="item in caseByIdList"
                :key="item.id"
                class="we-all-one"
              >
                <!--<router-link :to="item.machineType == 3 || 5 || 6 ? `/detailsGoodsNew/${item.id}/${1}` : `/detailsGoods/${item.id}/${1}`">-->
                <router-link :to="item.machineType == 3 || 5 || 6 ? {name: 'detailsGoodsNew', query: { id: item.id, houseNo: 1 }} : {name: 'detailsGoods', query: { id: item.id, houseNo: 1 }}">
                  <div class="we-content-one">
                    <div class="we-img">
                      <img :src="item.picture" alt="" />
                    </div>
                    <div class="text-one">{{ item.productName }}</div>
                    <div class="text-two">
                      {{ item.described }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { CaseById } from "../../api/solutions/index";
import AppLayout from "@/components/AppLayout";
import HomeBackup from "@/views/home/components/HomeBackup";
import { useRoute } from "vue-router";

const route = useRoute();
let id = ref(route.params.id);
const details = ref();
const title = ref();
const caseByIdList = ref([]);
const getCaseById = async () => {
  try {
    let data = await CaseById(id.value);
    if (data.code === 200) {
      data.data.details = data.data.details.replace(
        /<video/g,
        "<video style='width:961px;height:510px'"
      );
      details.value = data.data.details;
      title.value = data.data.title;
      caseByIdList.value = data.data.productInfoPOS;
      console.log(caseByIdList.value);
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getCaseById();
});
</script>

<style lang="less" scoped>
.content-warp {
  width: 1920px;
  background-color: #f2f2f2;
  padding-bottom: 40px;
  .warp-title {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    background: #f2f2f2;
    margin-top: 70px;
    .warp1-title1 {
      width: 1200px;
      min-height: 780px;
      margin: 0 auto;
      background: #fff;
      .warp-title-text {
        padding-top: 46px;
        margin-left: 95px;
        font-size: 18px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        span {
          display: inline-block;
          width: 19px;
          height: 15px;
          position: relative;
          top: -2px;
          right: 2px;
          img {
            display: inline-block;
            width: 19px;
            height: 15px;
          }
        }
      }
      .warp-title-content {
        margin-top: 22px;
        margin-left: 90px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        // text-align: center;
      }
      .divider-border {
        width: 1060px;
        margin-left: 74px;
        background-color: #e1e1e1;
      }

      .nva-border {
        border: 1px solid #e1e1e1;
        margin: 29px 50px 58px 50px;
      }
    }
    .warp-img {
      max-width: 961px;
      background-color: #fff;
      margin: 0 auto;
      padding-bottom: 48px;
    }
    .we-content {
      padding-bottom: 60px;
      .we-content-t {
        margin-left: 120px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .we-all {
        display: flex;
        margin-left: 120px;
        margin-top: 30px;
        .we-content-one {
          width: 248px;
          height: 308px;
          background: #f2f2f2;
          margin-right: 24px;
          cursor: pointer;
          .hoverShadow ();
          .we-img {
            width: 160px;
            height: 160px;
            margin-left: 43px;
            img {
              width: 160px;
              height: 160px;
              margin-top: 34px;
            }
          }
          .text-one {
            width: 134px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 53px;
            text-align: center;
            margin-left: 57px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .text-two {
            width: 198px;
            font-size: 13px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: 11px;
            text-align: center;
            margin-left: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
